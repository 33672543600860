import React from 'react'

import Layout from '../../components/Layout'
import Gallery from '../../components/Gallery'

export default class GalleryIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/blog-index.jpg')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow:
                'rgb(50, 115, 220) 0.5rem 0px 0px, rgb(50, 115, 220) -0.5rem 0px 0px',
              backgroundColor: 'rgb(50, 115, 220)',
              color: 'white',
              padding: '1rem',
            }}
          >
            照片集锦
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <Gallery />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
